<template>

  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <div class="row">
                <div class="col-md-6 col-xl-4">
                  <div class="py-1">
                    <i class="fa fa-users fa-2x text-info"></i>
                    <h3 class="text-info">{{ totalDriverOnboards }}</h3>
                    <p class="text-uppercase mb-1 font-13 font-weight-medium">
                      Total Prospective Drivers
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-xl-4">
                  <div class="py-1" @click.prevent="modalState=true;$bvModal.show('drivers')">
                    <i class="fa fa-user-shield fa-2x text-warning"></i>
                    <h3 class="text-warning" >{{ totalDriversInStage1 }}</h3>
                    <p class="text-uppercase mb-1 font-13 font-weight-medium">
                      Drivers in on-boarding stage
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-xl-4">
                  <div class="py-1" @click.prevent="modalState=false;$bvModal.show('drivers')">
                    <i class="fa fa-file fa-2x text-success"></i>
                    <h3 class="text-success">{{ totalDriversInStage2 }}</h3>
                    <p class="text-uppercase mb-1 font-13 font-weight-medium">
                      Drivers in file upload stage
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-6 col-md-2">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Display&nbsp;
                    <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    >&nbsp;Drivers
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-6 col-md-4">
                <div
                    class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Service Type:
                    <select class="form-control form-control-sm ml-2" @change="onFilterByStatus" v-model="service_type">
                      <option value="all"> All</option>
                      <option value="light_partner"> Light Partner</option>
                      <option value="light_partner_plus"> Light Partner Plus</option>
                      <option value="medium_partner"> Medium Partner</option>
                      <option value="premium_partner"> Premium Partner</option>
                    </select>
                  </label>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div
                    id="tickets-table_filters"
                    class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Status:
                    <select class="form-control form-control-sm ml-2" v-model="status_type" @change="onFilterByStatus">
                      <option value="all"> All</option>
                      <option value="in-progress"> Registered</option>
                      <option value="uploading-files"> Uploading Files</option>
                      <option value="service-selected"> Accepted Agreement</option>
                    </select>
                  </label>
                </div>
              </div>
              <!-- End search -->
              <!-- Search -->
              <div class="col-sm-6 col-md-3">
                <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <b-table
                table-class="table table-centered w-100"
                thead-tr-class="bg-light"
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
            >

              <template v-slot:cell(files)="data">
                {{ data.item.files.length > 0 ? data.item.files.length + ' file(s) uploaded' : 'NONE' }}
              </template>

              <template v-slot:cell(dossier)="data">
                {{ data.item['driver_with_incomplete_registration_files']['dossier'] || 'N/A' }}
              </template>

              <template v-slot:cell(prospective_driver.service_type)="data">
                {{
                  data.item.prospective_driver && data.item.prospective_driver.service_type ? formattedService(data.item.prospective_driver.service_type) : "Not selected"
                }}
              </template>


              <template v-slot:cell(prospective_driver.status)="data">
                <template v-if="data.item.prospective_driver">
                     <span
                         class="badge p-1"
                         :class="{
                        'badge-outline-danger': data.item.prospective_driver.status === 'in-progress' && data.item.files.length === 0,
                        'badge-outline-info': data.item.prospective_driver.status !== 'in-progress' || data.item.files.length > 0,
                      }"
                     >
                  {{
                         data.item.prospective_driver.status !== 'in-progress' || data.item.files.length > 0 ? 'uploading files' : formatStatusDisplay(data.item.prospective_driver.status)
                       }}</span
                     >
                </template>
                <template v-else>
                      <span
                          class="badge p-1"
                      >N/A</span>
                </template>

              </template>

              <template v-slot:cell(name)="data">
                <a href="javascript: void(0);" class="text-dark">
                  <b-avatar size="md" variant="light" class="text-dark"
                  ><span class="">{{ data.item.name | initials }}</span></b-avatar
                  >
                  <span class="ml-2">{{ data.item.name }} {{ data.item['other_names'] }}</span>
                </a>
              </template>


              <template v-slot:cell(action)="data">
                <router-link class="btn btn-soft-info" :to="'prospective-driver-detail/' + data.item.id ">
                  Details
                </router-link>
              </template>


            </b-table>
            <div class="row">
              <div class="col">
                <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        ref="driversTable"
        size="xl" centered
        id="drivers" title="Driver(s)">

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <p class="text-muted font-13 mb-4"></p>
              <div class="row mb-md-2">

                <div class="col-sm-12 col-md-4">
                  <div class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                      ></b-form-select
                      >&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Actions -->
                <!-- End search -->

                <!-- Search -->
                <div class="col-sm-12 col-md-4">
                  <div
                      class="dataTables_filter text-md-right"
                  >
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Search..."
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->
              <div class="table-responsive mb-0">
                <b-table
                    :items="modalState?bordingStageData:fileUploadStageDate"
                    :fields="fields"
                    ref="selectableTable"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    selectable
                >

                  <template v-slot:cell(dossier)="data">
                    {{ data.item['driver_with_incomplete_registration_files']['dossier'] || 'N/A' }}
                  </template>

                  <template v-slot:cell(files)="data">
                    {{ data.item.files.length > 0 ? data.item.files.length + ' file(s) uploaded' : 'NONE' }}
                  </template>

                  <template v-slot:cell(prospective_driver.service_type)="data">
                    {{
                      data.item.prospective_driver && data.item.prospective_driver.service_type ? formattedService(data.item.prospective_driver.service_type) : "Not selected"
                    }}
                  </template>

                  <template v-slot:cell(name)="row">
                    {{ row.item.name }} {{ row.item['other_names'] }}
                  </template>


                  <template v-slot:cell(prospective_driver.status)="data">
                    <template v-if="data.item.prospective_driver">
                     <span
                         class="badge p-1"
                         :class="{
                        'badge-outline-danger': data.item.prospective_driver.status === 'in-progress' && data.item.files.length === 0,
                        'badge-outline-info': data.item.prospective_driver.status !== 'in-progress' || data.item.files.length > 0,
                      }"
                     >
                  {{
                         data.item.prospective_driver.status !== 'in-progress' || data.item.files.length > 0 ? 'uploading files' : formatStatusDisplay(data.item.prospective_driver.status)
                       }}</span
                     >
                    </template>
                    <template v-else>
                      <span
                          class="badge p-1"
                      >N/A</span>
                    </template>

                  </template>

                  <template v-slot:cell(action)="data">
                    <router-link class="btn btn-soft-info" :to="'prospective-driver-detail/' + data.item.id ">
                      Details
                    </router-link>
                  </template>


                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                          v-model="currentPage"
                          :total-rows="modalRows"
                          :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </b-modal>

  </div>

</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import appConfig from "../../../../../app.config.json";

import {mapGetters} from "vuex";

/**
 * Tickets component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      service_type:'all',
      status_type:'all',
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "registered_at",
      sortDesc: true,
      fields: [
        {
          label: "Driver name",
          key: "name",
          sortable: true,
        },
        // {
        //   key: "email",
        //   sortable: true,
        // },
        {
          key: "phone",
          sortable: true,
        },
        {
          key: "dossier",
          sortable: true,
        },
        {
          label: "Service Type",
          key: "prospective_driver.service_type",
          sortable: true,
        },
        {
          label: "Status",
          key: "prospective_driver.status",
          sortable: true,
        },
        {
          key: "registeredAt",
          label: "Date Registered",
          sortable: true,
        },
        {
          label: "Files uploaded",
          key: "files",
          sortable: true,
        },
        "action",
      ],
      modalState:false,
    };
  },
  computed: {

    tableData() {
      return this.$store.getters.GET_PROSPECTIVE_DRIVERS;
    },
    bordingStageData(){
      return this.$store.getters.DriversInStage1;
    },
    fileUploadStageDate(){
      return this.$store.getters.DriversInStage2;
    },
    ...mapGetters([
      'totalDriverOnboards',
      'totalDriversInStage1',
      'totalDriversInStage2'
    ]),

    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    modalRows() {
      if(this.modalState){
        return this.totalDriversInStage1;
      }
      return this.totalDriversInStage2;
    },
  },
  mounted() {
    // Set the initial number of items
    //this.totalRows = this.items.length;
  },
  created() {
    this.$store.dispatch('fetchProspectiveDriverList');
    this.$store.dispatch('setPageTitle', 'Prospective drivers');
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFilterByStatus(event) {
      // const value = event.target.value;
      let data = {
        service: this.service_type,
        status: this.status_type,
      }
      this.$store.dispatch('filterProspectiveDriverList', data);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    formattedService(value) {
      return this.$options.filters.serviceType(value);
    },
    formatStatusDisplay(value) {
      if (value === "in-progress") {
        return "No service selected"
      } else if (value === "service-selected") {
        return "Service selected";
      }
      return value;
    }
  },
  middleware: "router-auth",
};
</script>
